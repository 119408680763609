// Code is mostly from https://uiball.com/ldrs/

.loader {
  --uib-size: 100px;
  --uib-color: var(--accent);
  --uib-speed: 1.5s;
  --uib-stroke: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.9);

  .bar {
    width: var(--uib-stroke);
    height: 100%;
    background-color: var(--uib-color);
    border-radius: calc(var(--uib-size) / 2);
    transition: background-color 0.3s ease;

    &:nth-child(1) {
      animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.45)
      infinite;
    }

    &:nth-child(2) {
      animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.3)
      infinite;
    }

    &:nth-child(3) {
      animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.15)
      infinite;
    }

    &:nth-child(4) {
      animation: grow var(--uib-speed) ease-in-out infinite;
    }

    @keyframes grow {
      0%,
      100% { height: 30%; }
      50% { height: 100%; }
    }
  }
}
