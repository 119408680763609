header {
    width: 100vw;
    height: 100svh;
    gap: 2rem;
    box-shadow: 0 0 10px black;
}

.header-container {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    background: inherit;
}
.header-title {
    font-size: 4rem;
    margin: 0;
}
.header-slogan {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.source-icons-container {
    margin: 0 1rem;
    padding: 1rem 2rem;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);
}

.scroll-hint {
    font-size: 1.5rem;
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translate(-50%, 0);
    transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
