.background {
    position: absolute;
    inset: 0;
    height: 100svh;
    z-index: -1;
}

.dashboard {
    > .background {
        position: fixed;
    }
}
