footer {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--bg-lvl1);
    box-shadow: 0 0 10px black;
}

.social-icons {
    gap: 1rem;

    a {
        color: var(--text);
        transition: color 0.25s ease-in-out;

        &:hover {
            color: var(--accent);
        }
    }
}
