:root {
    --bg: #121212;
    --bg-lvl1: #1d1d1d;
    --bg-lvl2: #212121;
    --bg-lvl3: #292929;

    --text: #ffffff;
    --hover: #808080;
    --accent: #e71d36;
    --accent-alt: #e76a1d;

    // react-toastify
    --toastify-color-info: #008DD5;
    --toastify-color-success: #8FCB9B; // #499F68
    --toastify-color-warning: var(--accent-alt);
    --toastify-color-error: var(--accent);

    --toastify-color-dark: var(--bg-lvl2);
    --toastify-toast-width: 25rem;
    --toastify-toast-padding: 1rem;

    .Toastify {
        width: 0;
        height: 0;
        position: fixed;
        margin: 0;
        padding: 0;
        z-index: 999;
    }
    .Toastify__toast {
        font-size: 1rem;
        white-space: pre-line;
    }
}

html {
    scroll-behavior: smooth;
    scroll-padding: 10vh;
    /* Hide Scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    color-scheme: dark;
    background: var(--bg);
}

::selection {
    background-color: var(--accent);
    color: white;
}

.app {
    width: 100vw;
    min-height: 100svh;
    color: var(--text);
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}
@media screen and (min-width: 768px)  { .app { font-size: 1.2rem; } }
@media screen and (min-width: 1600px) { .app { font-size: 1.5rem; } }
@media screen and (min-width: 2000px) { .app { font-size: 2rem;   } }

.small-text {
    color: var(--hover);
    font-size: 0.75rem;
}

a {
    color: var(--text);
    text-decoration: none;
    background-color: transparent;
    transition: color 0.25s ease-in-out;

    &:hover {
        color: var(--hover);
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }

    &.underline {
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;
    }
}

button {
    background: none;
    border: none;
    font: inherit;
    text-align: start;
    cursor: pointer;
    padding: 0;
}

.cta-button {
    font-size: 2rem;
    padding: 1rem;
    border: 5px solid var(--text);
    border-radius: 1rem;
    color: var(--text);
    mix-blend-mode: screen;
    text-align: center;
    background-color: transparent;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    cursor: pointer;

    &:hover,
    &:active {
        color: black;
        background-color: var(--text);
    }
}

p {
    text-align: center;
}

.flex-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    place-items: center;
    place-content: center;

    &.column {
        flex-direction: column;
    }

    &.start {
        place-content: flex-start;
        place-items: flex-start;
    }

    &.space-between {
        place-content: space-between;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }
}

.skeleton {
    flex: 1 1 auto;
}
