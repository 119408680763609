section {
    width: 100vw;

    padding: 5vh 10vw;
    margin: 100px 0;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 5vh;
    place-items: center;
    place-content: center;

    &.diagonal {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            box-shadow: 0 0 10px black;
            background: linear-gradient(to right, var(--accent), var(--accent-alt));
            transform: skewY(-2deg);
        }

        &.reverse::after {
            background: linear-gradient(to left, var(--accent), var(--accent-alt));
            transform: skewY(2deg);
        }
    }
}


section h2 {
    font-size: 3rem;
    text-align: center;
    margin: 0;
}
section h3 {
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
}

.features-container {
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: 1fr;
    grid-gap: 2vh;
}
@media screen and (min-width: 1024px) {
    .features-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.feature-card {
    --mouse-x: 50%;
    --mouse-y: 50%;
    --card-border-radius: 1rem;
    background-color: var(--accent);
    border-radius: var(--card-border-radius);
    display: flex;
    flex-direction: column;
    position: relative;

    &::before,
    &::after {
        border-radius: inherit;
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: opacity 500ms;
        pointer-events: none;
    }

    &::before {
        background: radial-gradient(
                        600px circle at var(--mouse-x) var(--mouse-y),
                        rgba(255, 255, 255, 0.1),
                        transparent 40%
        );
        z-index: 3;
    }

    &::after {
        background: radial-gradient(
                        800px circle at var(--mouse-x) var(--mouse-y),
                        var(--accent-alt),
                        transparent 40%
        );
        z-index: 1;
    }

    > .feature-card-content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        padding: 15px;
        z-index: 2;
        cursor: default;
        border-radius: inherit;
        color: var(--text);
        transition: color 0.5s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            inset: 4px;
            z-index: -1;
            background-color: var(--bg-lvl1);
            border-radius: calc(var(--card-border-radius) - 2px);
        }
    }
}
.feature-card:hover::before, .features-container:hover > .feature-card::after {
    opacity: 1;
}

.commands-container {
    display: flex;
    flex-wrap: wrap;
    place-content: center;
    gap: 3rem;
    padding: 3rem 0;

    > code {
        padding: 1rem;
        position: relative;
        background-color: var(--bg-lvl1);
        border-radius: 10px;
        transform-style: preserve-3d;
        cursor: default;
        user-select: none;

        &::before {
            content: "";
            position: absolute;
            inset: -0.5rem;
            border-radius: inherit;
            background: linear-gradient(to bottom right, var(--accent), var(--accent-alt));
            transform: translateZ(-20px);
        }
    }
}
