.dashboard {
    --gap: 0.5rem;
    height: 100svh;
    padding: var(--gap) var(--gap) calc(7rem + var(--gap)) var(--gap);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
        'header header header'
        '. . .';
    gap: var(--gap);

    @media screen and (max-width: 768px) {
        & {
            height: auto;
            grid-template-columns: 1fr;
            grid-template-rows: min-content 1fr min-content min-content;
            grid-template-areas:
                'header'
                '.'
                '.'
                '.';
            transition: grid-template-rows 0.5s ease-in-out;

            &:has(.server-container.collapsed) {
                grid-template-rows: min-content 0fr min-content min-content;
            }
            &:has(.player-bar.expanded) {
                > *:not(.background, .player-bar) {
                    pointer-events: none;
                    opacity: 0;
                    transition: opacity 0.25s ease-in-out;
                }
            }
            > :not(.background, .player-bar):not(.\9) {
                max-height: 50svh;
                min-height: 1.75rem;
            }

            .dashboard-header.dashboard-header {
                .dashboard-header-links {
                    display: none;
                }
                .dashboard-header-user .dashboard-header-logout {
                    opacity: 1;
                    position: relative;
                    background: none;
                    text-indent: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 1.5rem;
                    height: 1.5rem;

                    svg {
                        position: absolute;
                        inset: 0;
                    }
                }
            }

        }
    }

    > :not(.background, .player-bar) {
        padding: 1rem;
        background-color: var(--bg-lvl1);
        border-radius: 0.5rem;
        box-shadow: 2px 2px 5px black;
    }

    .dashboard-header {
        grid-area: header;
        justify-content: space-between;
        height: 2rem;

        > * {
            flex: 1 1 0;
            width: 100%;
            height: 100%;
        }

        .dashboard-header-title {
            height: 100%;
            justify-content: start;

            > img {
                height: 100%;
                transition: opacity 0.25s ease-in-out;
            }
            &:hover > img {
                opacity: 0.5;
            }
            font-weight: bold;
        }

        .dashboard-header-links {
            height: 100%;

            > a {
                height: 100%;
                aspect-ratio: 16 / 9;
                display: grid;
                place-items: center;
                font-size: 1rem;
                border-radius: 0.5rem;
                box-shadow: 2px 2px 5px black;
                transition: transform 0.25s ease-in-out;

                &:hover {
                    color: var(--text);
                    transform: scale(110%);
                }
            }
        }

        .dashboard-header-user-container {
            justify-content: end;
        }
        .dashboard-header-user {
            position: relative;
            z-index: 1;
            width: fit-content;
            height: 100%;
            font-size: 1rem;
            border-radius: 0.5rem;
            //max-width: calc(25% - 3rem);

            > span {
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
            .dashboard-header-icon {
                height: 100%;
                aspect-ratio: 1/1;
                border-radius: 50%;
            }
            > .dashboard-header-logout {
                position: absolute;
                inset: 0;
                border-radius: inherit;
                background: linear-gradient(to bottom right, var(--accent), var(--accent-alt));
                opacity: 0;
                transition: opacity 0.25s ease-in-out;

                &:hover {
                    color: var(--text);
                    opacity: 1;
                }
            }
        }
    }
}
