.navbar {
    width: 100vw;
    height: 10vh;

    box-sizing: border-box;
    padding: 20px 5vw;

    position: fixed;
    z-index: 999;
    background-color: var(--bg);
    box-shadow: 0 0 10px black;
    transition: background-color 0.25s ease-in-out;

    &.transparent {
        background-color: rgba(0, 0, 0, 50%);
    }
}

.nav-logo-container {
    opacity: 100%;
    transition: opacity 0.25s ease-in-out;

    &:hover {
        color: var(--text);
        opacity: 50%;
    }
}
.nav-title {
    font-size: 1rem;
    font-weight: bold;
}
.nav-logo {
    width: 1.5rem;
    height: 1.5rem;
}

.nav-link-container {
    gap: 1rem;

    a {
        font-size: 1rem;
        text-transform: uppercase;
    }

    .nav-separator {
        height: 1.5rem;
        width: 0;
        border-left: 2px solid white;
        box-sizing: border-box;
    }
}

.hideOnMobile {
    display: none !important;
}

@media screen and (min-width: 768px) {
    .hideOnMobile {
        display: flex !important;
    }
}
